@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: Basier, helvetica, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }

  /*
  Tailwind's `base` layer includes a CSS reset that removes all styling from elements and sets a few helpful defaults. Our application styles rely on the browser's default styling for many elements, so we're overriding Tailwind's reset styles here to preserve them.

  Eventually, we'll want to remove the overrides below and explicitly style elements using Tailwind utilities instead and have low-level elements like `HeadingOne` and `Paragraph` components that apply those utilities.

  This is temporary.
*/
  h1 {
    display: block;
    font-size: 2em;
    margin-top: 0.67em;
    margin-bottom: 0.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h4 {
    display: block;
    font-size: 1em;
    margin-top: 1.33em;
    margin-bottom: 1.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h5 {
    display: block;
    font-size: 0.83em;
    margin-top: 1.67em;
    margin-bottom: 1.67em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  h6 {
    display: block;
    font-size: 0.67em;
    margin-top: 2.33em;
    margin-bottom: 2.33em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }

  p {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
  }

  ul,
  ol {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ul ul {
    list-style-type: circle;
    margin-top: 0;
    margin-bottom: 0;
  }

  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  [data-reach-popover] {
    z-index: 1000;
  }

  /*
   * Color System
  */
  :root {
    /* Base Colors */
    --neutral-0: 255 255 255; /* #FFFFFF */
    --neutral-50: 250 251 252; /* #FAFBFC */
    --neutral-100: 246 248 250; /* #F6F8FA */
    --neutral-200: 226 228 233; /* #E2E4E9 */
    --neutral-300: 205 208 213; /* #CDD0D5 */
    --neutral-400: 134 140 152; /* #868C98 */
    --neutral-500: 82 88 102; /* #525866 */
    --neutral-600: 49 53 63; /* #31353F */
    --neutral-700: 32 35 45; /* #20232D */
    --neutral-800: 22 25 34; /* #161922 */
    --neutral-900: 10 13 20; /* #0A0D14 */

    --teal-darker: 18 37 45; /* #12252D */
    --teal-dark: 13 101 101; /* #0D6565 */
    --teal-base: 51 153 153; /* #339999 */
    --teal-light: 171 228 223; /* #ABE4DF */
    --teal-lighter: 213 242 239; /* #D5F2EF */
    --teal-lightest: 235 248 247; /* #EBF8F7 */

    --orange-darker: 123 26 19; /* #7B1A13 */
    --orange-dark: 250 128 83; /* #FA8053 */
    --orange-base: 253 102 60; /* #FD663C */
    --orange-light: 253 200 171; /* #FDC8AB */
    --orange-lighter: 255 229 213; /* #FFE5D5 */
    --orange-lightest: 255 244 238; /* #FFF4EE */

    --blue-darker: 17 50 100; /* #113264 */
    --blue-dark: 13 116 206; /* #0D74CE */
    --blue-base: 5 136 240; /* #0588F0 */
    --blue-light: 142 200 246; /* #8EC8F6 */
    --blue-lighter: 230 244 254; /* #E6F4FE */
    --blue-lightest: 245 250 255; /* #F5FAFF */

    --green-darker: 25 59 45; /* #193B2D */
    --green-dark: 33 131 88; /* #218358 */
    --green-base: 43 154 102; /* #2B9A66 */
    --green-light: 142 206 170; /* #8ECEAA */
    --green-lighter: 230 246 235; /* #E6F6EB */
    --green-lightest: 245 251 247; /* #F5FBF7 */

    --red-darker: 100 23 35; /* #641723 */
    --red-dark: 206 44 49; /* #CE2C31 */
    --red-base: 220 62 66; /* #DC3E42 */
    --red-light: 244 169 170; /* #F4A9AA */
    --red-lighter: 255 205 206; /* #FFCDCE */
    --red-lightest: 255 238 238; /* #FFECEE */

    --yellow-darker: 79 52 34; /* #4F3422 */
    --yellow-dark: 171 100 0; /* #AB6400 */
    --yellow-base: 255 186 24; /* #FFBA18 */
    --yellow-light: 233 193 98; /* #E9C162 */
    --yellow-lighter: 255 247 194; /* #FFF7C2 */
    --yellow-lightest: 255 251 230; /* #FFFBE6 */

    --purple-darkest: 64 32 96; /* #402060 */
    --purple-darker: 129 69 181; /* #8145B5 */
    --purple-base: 131 71 185; /* #8347B9 */
    --purple-light: 209 175 236; /* #D1AFEC */
    --purple-lighter: 234 213 249; /* #EAD5F9 */
    --purple-lightest: 247 235 254; /* #F7EBFE */

    --pink-darkest: 101 18 73; /* #651249 */
    --pink-darker: 194 41 138; /* #C2298A */
    --pink-base: 207 56 151; /* #CF3897 */
    --pink-light: 231 172 208; /* #E7ACD0 */
    --pink-lighter: 246 206 231; /* #F6CEE7 */
    --pink-lightest: 253 234 244; /* #FDEAF4 */

    /* Semantic Colors */
    --primary-darker: var(--teal-darker);
    --primary-dark: var(--teal-dark);
    --primary-base: var(--teal-base);
    --primary-light: var(--teal-light);
    --primary-lighter: var(--teal-lighter);
    --primary-lightest: var(--teal-lightest);

    --secondary-darker: var(--orange-darker);
    --secondary-dark: var(--orange-dark);
    --secondary-base: var(--orange-base);
    --secondary-light: var(--orange-light);
    --secondary-lighter: var(--orange-lighter);
    --secondary-lightest: var(--orange-lightest);

    --bg-strong: var(--neutral-900);
    --bg-surface: var(--neutral-700);
    --bg-soft: var(--neutral-200);
    --bg-weaker: var(--neutral-100);
    --bg-foundation: var(--neutral-0);

    --text-primary: var(--neutral-900);
    --text-secondary: var(--neutral-500);
    --text-placeholder: var(--neutral-400);
    --text-tertiary: var(--neutral-400);
    --text-disabled: var(--neutral-300);
    --text-inverse: var(--neutral-0);

    --icon-primary: var(--neutral-900);
    --icon-secondary: var(--neutral-500);
    --icon-tertiary: var(--neutral-400);
    --icon-disabled: var(--neutral-300);
    --icon-inverse: var(--neutral-0);

    --stroke-primary: var(--neutral-900);
    --stroke-secondary: var(--neutral-300);
    --stroke-tertiary: var(--neutral-200);
    --stroke-disabled: var(--neutral-100);
    --stroke-inverse: var(--neutral-0);

    --state-neutral: var(--neutral-400);
    --state-success: var(--green-dark);
    --state-warning: var(--yellow-dark);
    --state-error: var(--red-dark);
    --state-info: var(--blue-dark);

    --btn-primary: var(--teal-base);
    --btn-primary-hover: var(--teal-dark);
    --btn-primary-active: var(--teal-darker);

    --btn-secondary: var(--neutral-800);
    --btn-secondary-hover: var(--neutral-600);
    --btn-secondary-active: var(--neutral-500);

    --btn-tertiary: var(--neutral-100);
    --btn-tertiary-hover: var(--neutral-200);
    --btn-tertiary-active: var(--neutral-300);

    --btn-destructive: var(--red-base);
    --btn-destructive-hover: var(--red-dark);
    --btn-destructive-active: var(--red-darker);

    --link-primary: var(--teal-base);
    --link-primary-hover: var(--teal-dark);

    --link-inverse: var(--teal-light);
    --link-inverse-hover: var(--teal-lighter);

    --field-1: var(--neutral-100);
    --field-2: var(--neutral-0);
    --field-3: var(--neutral-100);
  }
}

@layer components {
  .content-container {
    @apply mx-auto w-full max-w-[1767px] px-5 pb-8 pt-8 md:px-10 lg:pb-16 lg:pt-16;
  }
  .content-container-full {
    @apply w-full px-5 pb-8 pt-8 md:px-10 lg:pb-16 lg:pt-16;
  }

  .grow-wrap {
    /* easy way to plop the elements on top of each other and have them both sized based on the tallest one's height */
    display: grid;
  }
  .grow-wrap::after {
    /* Note the weird space! Needed to preventy jumpy behavior */
    content: attr(data-content) " ";

    /* This is how textarea text behaves */
    white-space: pre-wrap;

    /* Hidden from view, clicks, and screen readers */
    visibility: hidden;
  }
  .grow-wrap > textarea {
    /* You could leave this, but after a user resizes, then it ruins the auto sizing */
    resize: none;

    /* Firefox shows scrollbar on growth, you can hide like this. */
    overflow: hidden;
  }
  .grow-wrap > textarea,
  .grow-wrap::after {
    /* Identical styling required!! */
    font: inherit;

    /* Place on top of each other */
    grid-area: 1 / -1;
  }
}

@layer base {
  /**
    * Scrollbar Styles
    */
  :root {
    --scrollbar-color-thumb: #868c984c;
    --scrollbar-color-track: transparent;
    --scrollbar-width: thin;
    --scrollbar-width-legacy: 8px;
  }

  /* Only want to add these styles on non-touch devices  */
  @media (hover: hover) and (pointer: fine) {
    /* Modern browsers with `scrollbar-*` support */
    @supports (scrollbar-width: auto) {
      html,
      html * {
        scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
        scrollbar-width: var(--scrollbar-width);
      }
    }

    /* Legacy browsers with `::-webkit-scrollbar-*` support */
    @supports selector(::-webkit-scrollbar) {
      html::-webkit-scrollbar-thumb,
      html::-webkit-scrollbar:horizontal,
      html *::-webkit-scrollbar-thumb,
      html *::-webkit-scrollbar:horizontal {
        background: var(--scrollbar-color-thumb);
        border-radius: var(--scrollbar-width-legacy);
      }
      html::-webkit-scrollbar-track,
      html::-webkit-scrollbar:horizontal,
      html *::-webkit-scrollbar-track,
      html *::-webkit-scrollbar:horizontal {
        background: var(--scrollbar-color-track);
      }
      html::-webkit-scrollbar,
      html::-webkit-scrollbar:horizontal,
      html *::-webkit-scrollbar,
      html *::-webkit-scrollbar:horizontal {
        max-width: var(--scrollbar-width-legacy);
        max-height: var(--scrollbar-width-legacy);
      }
    }
  }

  html {
    /* Better Font Rendering */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  a:focus-visible,
  button:focus-visible,
  *[role="button"]:focus-visible {
    @apply ring-ds-stroke-tertiary outline-none ring-2;
  }
}

@layer utilities {
  .scrollbar-none {
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
  }
  .scrollbar-none::-webkit-scrollbar {
    display: none;
  }
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

:root {
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
}

iframe {
  width: 100%;
  height: 100%;
}
