.animation-reveal-overlay {
  animation-name: reveal-overlay;
  @apply default-timing;
}
.animation-reveal-content {
  animation-name: reveal-content;
  @apply default-timing;
}

@keyframes reveal-overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes reveal-content {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
