.toast {
  display: grid;
  grid-template-areas:
    'icon content action'
    'icon content action';
  grid-template-columns: auto 1fr auto;
}

/* If the Toast has a cancel button, but no action button, then the cancel button should expand the full container height */
.toast:has([data-cancel='true']):not(:has(.actionButton)) [data-cancel='true'] {
  grid-row: 1/-1;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

/* If the Toast has an action button, but no cancel button, then the action button should expand the full container height */
.toast:has(.actionButton):not(:has([data-cancel='true'])) .actionButton {
  grid-row: 1/-1;
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

/* If the Toast has both a cancel button and an action button, then the cancel button should be below the action button */
.toast:has([data-cancel='true']):has(.actionButton) [data-cancel='true'] {
  grid-row-start: 2;
  grid-row-end: -1;
  border-bottom-right-radius: 0.75rem;
  border-top: 1px solid rgb(var(--stroke-tertiary));
}

/* If the Toast has both a cancel button and an action button, then the action button should be above the cancel button */
.toast:has([data-cancel='true']):has(.actionButton) .actionButton {
  grid-row-start: 1;
  grid-row-end: 2;
  border-top-right-radius: 0.75rem;
}
